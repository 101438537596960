import * as React from "react";
import { graphql } from "gatsby";
import { ITermsUseProps } from "../pageHelpers/TermsUse/TermsUseProps";
import Layout from "../components/global/Layout/Layout";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../components/global/SEO/SEO";

const TermsUse: React.FC<ITermsUseProps> = (props) => {
  const _site = props.data.allRlhsite.edges[0].node;
  let terms;
  if (props.data.allNodePage.edges.length) {
    terms = props.data.allNodePage.edges[0].node;
  }
  const _title = terms ? terms.tile : "";
  const _body = terms ? terms.body.value : "";
  return (
    <Layout {...{ site: _site }}>
      <SEO title="Terms Conditions" />
      <Container>
        <Row>
          <Col>
            {terms !== null && (
              <>
                <h1>{_title}</h1>
                <div dangerouslySetInnerHTML={{ __html: _body }} />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const pageQuery = graphql`
  query TermsUseQuery {
    allNodePage(filter: { path: { alias: { eq: "/v1/terms-service" } } }) {
      edges {
        node {
          title
          field_meta_description
          path {
            alias
          }
          body {
            processed
            value
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default TermsUse;
